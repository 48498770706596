

































import { Component, Model, Prop, Vue } from "vue-property-decorator";
import { AttachmentHostType } from "@/api/appService";

@Component({
  name: "SimpleUploadImage",
})
export default class SimpleUploadImage extends Vue {
  @Model("change", { type: String, default: "" }) public url!: string;

  @Prop({ required: true })
  public hostType!: AttachmentHostType;

  @Prop({ required: false, default: false })
  public showDelete!: boolean;

  public onProgress = false;
  public uploadPercent = 0;

  public get headers(): any {
    return {
      "Abp-OrganizationUnitId": (this as any).$store.getters["user/ouId"] || "",
      "Abp-TenantId": (this as any).$store.getters["app/tenantId"],
      Authorization: `Bearer ${(this as any).$store.getters["user/token"]}`,
      ".AspNetCore.Culture": "c=zh-Hans|uic=zh-Hans",
    };
  }

  public get uploadParameter(): any {
    return { hostType: this.hostType };
  }

  public handleSuccess(res: any, file: any): void {
    this.onProgress = false;
    this.uploadPercent = 100;
    if (res.success) {
      this.$emit("change", res.result.url);
      // this.url = res.result.url;
      // this.$emit('change', res.result.url)
      this.$nextTick();
    }
  }

  public handleProgress(e: any): void {
    this.onProgress = true;
    this.uploadPercent = e.percent;
    console.log("handleProgress", e);
  }

  public handleRemove(): void {
    this.$emit("change", "");
  }

  private beforeUpload(file: any) {
    console.log(file);
    const isJPG = file.type === "image/jpeg";
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      this.$message.error("上传头像图片大小不能超过 5MB!");
      return false;
    } else {
      return true;
    }
  }
}
